import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Grid,
    IconButton,
    Tooltip,
    Typography,
} from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams, GridToolbarQuickFilter } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import React, { useEffect, useState } from 'react';

import { deleteUserCredentials } from './repositories/authInformationRepository';
import { getAllUsers, getCRMUsers } from './repositories/userRepository';
import { useParams } from 'react-router-dom';
import NewUserForm from './NewUserForm';
import { enqueueSnackbar } from 'notistack';
import { UserDTO } from '../../types/UserDTO';
import { getCompanyByID } from '../company/repositories/companyRepository';
import { CRMUserDTO } from '../../types/CRMUserDTO';

const UserIndex = () => {
    const { companyId } = useParams() as { companyId: string };
    const companyName = localStorage.getItem('companyName');
    const [users, setUsers] = useState<UserDTO[]>([]);
    const [crmUsers, setCRMUsers] = useState<CRMUserDTO[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [isIntegratedUsersExpanded, setIsIntegratedUsersExpanded] = React.useState(true);
    const [isCRMUsersExpanded, setIsCRMUsersExpanded] = React.useState(false);
    const [hasFetchedCRMUsers, setHasFetchedCRMUsers] = useState(false);
    type Row = (typeof users)[number];

    const fetchCompany = async () => {
        if (companyId !== localStorage.getItem('companyID')) {
            const newCompany = await getCompanyByID(companyId);
            localStorage.setItem('companyName', newCompany?.name || '');
            localStorage.setItem('companyID', newCompany?.id || '');
        }
    };

    useEffect(() => {
        if (isLoading) {
            fetchCompany();
            setIsLoading(false);
            getAllUsers(companyId).then((data) => {
                setUsers(data);
                setIsLoading(false);
            });
        }
        if (isCRMUsersExpanded && !hasFetchedCRMUsers) {
            getCRMUsers(companyId).then((data) => {
                setCRMUsers(data);
                setHasFetchedCRMUsers(true);
            });
        }
    }, [companyId, isLoading, isCRMUsersExpanded]);

    const onFormClose = () => {
        setIsFormOpen(false);
        refreshUsers();
    };

    const refreshUsers = React.useCallback(() => {
        setIsLoading(true);
    }, []);

    const copyIDToClipboard = (value: string) => {
        navigator.clipboard.writeText(value).then(() => console.log('Copied to clipboard'));
        enqueueSnackbar('Copied successfully', { variant: 'success' });
    };

    const companyNameComponent = (
        <Grid item xs={12} sx={{ mb: 4 }}>
            <Typography align="center" variant="h3">
                {companyName}
            </Typography>
        </Grid>
    );

    const crmColumns: GridColDef[] = [
        { field: 'id', headerName: 'Id', flex: 0.2 },
        { field: 'name', headerName: 'Name', flex: 0.2 },
        { field: 'username', headerName: 'Username', flex: 0.3 },
        { field: 'email', headerName: 'Email', flex: 0.3 },
    ];

    const columns = React.useMemo<GridColDef<Row>[]>(() => {
        return [
            { field: 'username', headerName: 'Username', flex: 0.3 },
            {
                field: 'isHealthy',
                headerName: 'Connection Active',
                flex: 0.2,
                headerAlign: 'center',
                align: 'center',
                renderCell: (params: GridRenderCellParams) =>
                    params.value ? (
                        <CheckCircleIcon sx={{ color: 'green', height: '100%' }} />
                    ) : (
                        <CancelIcon sx={{ color: 'red', height: '100%' }} />
                    ),
            },
            {
                field: 'id',
                headerName: 'Bridge ID',
                flex: 0.15,
                headerAlign: 'center',
                align: 'center',
                renderCell: (params: GridRenderCellParams) => (
                    <strong>
                        <Tooltip title="Copy User Bridge ID" arrow>
                            <IconButton onClick={() => copyIDToClipboard(params.value)}>
                                <ContentCopyIcon />
                            </IconButton>
                        </Tooltip>
                    </strong>
                ),
            },
            {
                field: 'Auth Credentials',
                headerName: 'Auth Credentials',
                flex: 0.15,
                headerAlign: 'center',
                align: 'center',
                renderCell: (params: GridRenderCellParams) => (
                    <strong>
                        <Button
                            variant="contained"
                            size="small"
                            onClick={() => {
                                deleteUserCredentials(params.row.id).then(() => {
                                    refreshUsers();
                                });
                            }}
                            sx={{ backgroundColor: 'red', '&:hover': { backgroundColor: 'darkred' } }}
                        >
                            Delete Auth
                        </Button>
                    </strong>
                ),
            },
            {
                field: 'Events configuration',
                headerName: 'Events configuration',
                flex: 0.2,
                headerAlign: 'center',
                align: 'center',
                renderCell: (params: GridRenderCellParams) => (
                    <strong>
                        <Button variant="contained" size="small" href={`users/${params.row.id}/events`}>
                            Events configuration
                        </Button>
                    </strong>
                ),
            },
        ];
    }, []);

    return (
        <Box width="100%" height="100%" sx={{ p: 6 }}>
            {companyNameComponent}
            <Box
                m="20px"
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Accordion
                    expanded={isIntegratedUsersExpanded}
                    onChange={() => setIsIntegratedUsersExpanded(!isIntegratedUsersExpanded)}
                    sx={{ marginBottom: '20px' }}
                >
                    <AccordionSummary
                        id="panel-header"
                        aria-controls="panel-content"
                        sx={{ backgroundColor: '#f0f0f0', borderBottom: '1px solid #ddd' }}
                    >
                        Integrated users
                    </AccordionSummary>
                    <AccordionDetails style={{ maxHeight: '65vh', overflow: 'auto' }}>
                        <NewUserForm isOpen={isFormOpen} onClose={onFormClose} />
                        <Box
                            sx={{
                                height: '60vh',
                                width: '75vw',
                                backgroundColor: 'white',
                            }}
                        >
                            <DataGrid
                                sx={{
                                    '--DataGrid-containerBackground': 'white',
                                }}
                                rows={users}
                                columns={columns}
                                slots={{
                                    toolbar: () => (
                                        <Box
                                            sx={{
                                                p: 1,
                                                pb: 0,
                                                m: 0.5,
                                            }}
                                        >
                                            <Button
                                                size="small"
                                                sx={{ m: 1 }}
                                                variant="contained"
                                                startIcon={<AddIcon />}
                                                onClick={() => setIsFormOpen(true)}
                                            >
                                                Add new user
                                            </Button>
                                            <GridToolbarQuickFilter sx={{ m: 1 }} />
                                        </Box>
                                    ),
                                }}
                                disableRowSelectionOnClick
                                loading={isLoading}
                            />
                        </Box>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={isCRMUsersExpanded}
                    onChange={() => setIsCRMUsersExpanded(!isCRMUsersExpanded)}
                    sx={{ marginBottom: '20px' }}
                >
                    <AccordionSummary
                        id="panel-header"
                        aria-controls="panel-content"
                        sx={{ backgroundColor: '#f0f0f0', borderBottom: '1px solid #ddd' }}
                    >
                        CRM users
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box
                            sx={{
                                height: '60vh',
                                width: '75vw',
                                backgroundColor: 'white',
                            }}
                        >
                            <DataGrid
                                sx={{
                                    '--DataGrid-containerBackground': 'white',
                                }}
                                rows={crmUsers}
                                columns={crmColumns}
                                loading={isLoading}
                                slots={{
                                    toolbar: () => (
                                        <Box
                                            sx={{
                                                p: 1,
                                                pb: 0,
                                                m: 0.5,
                                            }}
                                        >
                                            <GridToolbarQuickFilter sx={{ m: 1 }} />
                                        </Box>
                                    ),
                                }}
                            />
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </Box>
    );
};

export default UserIndex;
