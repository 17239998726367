import { UserAPIRepresentation, UserDTO } from '../../../types/UserDTO';

const presentUserFromAPI = (user: UserAPIRepresentation): UserDTO => {
    return {
        id: user.id,
        companyId: user.company_id,
        username: user.username,
    };
};

export default presentUserFromAPI;
